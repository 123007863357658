

const Bubble = () => {
    
    return (
        <div className="bubble__right">
            <div className="bubble__img">
                <div className="bubble__blur">
                </div>
            </div>
        </div>
    );
  };
  
export default Bubble;